import React, { FC } from 'react';
import { Box, Button, TextField as MUITextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { checkPromoCode } from 'store/reducers/PromotionSlice/ActionCreators';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import { SnackActions } from 'components/generic';
import {
  selectPromotionsData,
  selectPromotionsWithPromocode,
} from 'store/selectors/promotionSelectors';
import { selectBrandId } from 'store/selectors/brandsSelectors';
import { calculateBasket } from 'store/reducers/OrderSlice/ActionCreators';
import { selectApiKey } from 'store/selectors/accountSelectors';
import { selectAddressForBasket } from 'store/selectors/addressSelectors';
import {
  selectBasketDataForRequest,
  selectIsDelivery,
  selectOrderPromotions,
} from 'store/selectors/orderSelectors';
import { Promotion } from 'types/Promotion';
import { OrderPromotion } from 'types/Order';

const Promocode: FC = () => {
  const dispatch = useAppDispatch();
  const methods = useForm();
  const { watch, reset } = methods;
  const code = watch('promocode');
  const { addPromotion, removePromotion } = orderSlice.actions;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const promoWithCode = useAppSelector(selectPromotionsWithPromocode);
  const promotions = useAppSelector(selectPromotionsData);
  const brandId = useAppSelector(selectBrandId);
  const api_key = useAppSelector(selectApiKey);
  const basketData = useAppSelector(selectBasketDataForRequest);
  const selectedAddressData = useAppSelector(selectAddressForBasket);
  const isDelivery = useAppSelector(selectIsDelivery);
  const orderPromotions = useAppSelector(selectOrderPromotions);

  const getPromotion = (promo: Promotion): OrderPromotion => {
    if (promo.promo_dishes.length > 0) {
      return {
        action_id: promo.id,
        item_option_id: promo.promo_dishes[0].item_option,
      };
    }

    return {
      action_id: promo.id,
    };
  };

  const handleCheckCode = () => {
    dispatch(
      checkPromoCode({
        api_key: '',
        platform: 2,
        promocode: code,
        brand_id: brandId,
      })
    )
      .unwrap()
      .then((res) => {
        if (res.success && res.data.length > 0) {
          const promo = promoWithCode.find((item) => item.id === res.data[0]);
          const avalibalePromo = promotions.filter(
            (item) => item.id === res.data[0]
          );

          if (avalibalePromo.length > 0 && promo) {
            const orderPromotion = getPromotion(promo);

            dispatch(
              calculateBasket({
                api_key,
                platform: 2,
                brand_id: 0,
                body: {
                  basket: basketData,
                  is_delivery: isDelivery,
                  address: selectedAddressData,
                  actions: [...orderPromotions, orderPromotion],
                },
              })
            )
              .unwrap()
              .then((res) => {
                if (!res.success) return;

                if (res.data.actions.includes(promo.id)) {
                  dispatch(addPromotion(orderPromotion));

                  enqueueSnackbar(t('promo_code_successfully_applied'), {
                    action: SnackActions,
                    variant: 'success',
                    autoHideDuration: 3000,
                    preventDuplicate: true,
                  });
                  reset();
                } else {
                  enqueueSnackbar(t('promo_code_not_applied'), {
                    action: SnackActions,
                    variant: 'error',
                    autoHideDuration: 3000,
                    preventDuplicate: true,
                  });

                  dispatch(removePromotion(promo.id));
                }
              });
          } else {
            enqueueSnackbar(t('promo_code_not_found'), {
              action: SnackActions,
              variant: 'error',
              autoHideDuration: 3000,
              preventDuplicate: true,
            });
          }
        }

        if (
          !res.success ||
          res.reason === 'WRONG_PROMOCODE' ||
          res.data.length <= 0
        ) {
          enqueueSnackbar(t('promo_code_not_found'), {
            action: SnackActions,
            variant: 'error',
            autoHideDuration: 3000,
            preventDuplicate: true,
          });
        }
      });
  };

  return (
    <Box sx={{ padding: { xs: '0', md: '0 25px' } }}>
      <Controller
        name="promocode"
        control={methods.control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <MUITextField
            sx={{
              '& .MuiOutlinedInput-input': {
                padding: '13.5px 14px',
              },
              '& .MuiInputLabel-root': {
                color: '#BDBDBD',
                lineHeight: '20px',
              },
              '& .MuiInputBase-root': {
                paddingRight: '0',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: 'secondary.main',
              },
              '& .MuiInputLabel-shrink': {
                color: 'text.primary',
                backgroundColor: 'primary.main',
                padding: '0px 5px',
                left: '-5px',
                top: '2px',
                fontWeight: '600',
                fontSize: '13px',
                lineHeight: '20px',
              },
              '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
                '& fieldset': {
                  borderColor: 'primary.main',
                },
                '&:hover fieldset': {
                  border: '1px solid',
                  borderColor: 'secondary.main',
                },
                '&.Mui-focused fieldset': {
                  border: '1px solid',
                  borderColor: 'secondary.main',
                },
              },
              '& .MuiFormHelperText-root': {
                position: 'absolute',
                top: '45px',
                left: '0',
              },
            }}
            placeholder={t('promocode')}
            hiddenLabel
            onChange={onChange}
            value={value || ''}
            size="medium"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <Button
                  sx={{
                    fontSize: '17px',
                    lineHeight: '20px',
                    height: '50px',
                    fontWeight: '300',
                    padding: '0px 30px',
                    minWidth: 'auto',
                    boxShadow: 'none',
                    borderRadius: '6px',
                    span: {
                      display: 'inline-block',
                    },
                    '&:hover, :focus': {
                      boxShadow: 'none',
                    },
                  }}
                  variant="contained"
                  onClick={handleCheckCode}
                >
                  Применить
                </Button>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

export default Promocode;
