import { useState, useMemo, useEffect } from 'react';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectServerTime } from 'store/selectors/appSelectors';
import {
  selectLocationCity,
  selectTimeZone,
} from 'store/selectors/citySelectors';
import {
  selectCheckDelivery,
  selectIsDelivery,
} from 'store/selectors/orderSelectors';
import { SelectData } from 'types/SelectData';
import moment from 'moment-timezone';

/**
 * Округляет минуты момента в направлении midpoint так, чтобы округлённые минуты
 * были кратны offset.
 *
 * @param m Момент времени, который будет округлён
 * @param offset Значение, кратными которому должны стать минуты
 * @param midpoint Направление, в сторону которого минуты будут округляться.
 *   Если значение равно `up`, до минуты будут округляться в бОльшую сторону;
 *   иначе, если значение равно `down`, в меньшую.
 * @returns Момент с округлёнными минутами
 */
const roundMomentMinutes = (
  m: moment.Moment,
  offset: number,
  midpoint: 'up' | 'down'
): moment.Moment => {
  const minutes = m.minutes();

  let roundingFunction: (x: number) => number;
  if (midpoint === 'up') {
    roundingFunction = Math.ceil;
  } else {
    roundingFunction = Math.floor;
  }

  // TODO maybe should add 1 when using ceil
  const roundedMinutes = roundingFunction(minutes / offset) * offset;
  return moment(m).minutes(roundedMinutes);
};

/**
 * Разделяет временной интервал на отрезки одинаковой длины
 * и возвращает список времени на границах отрезков
 * @param start Начало временного интервала
 * @param end Конец временного интервала
 * @param timeInterval Продолжительность временных отрезков
 * @returns Список временных точек между `start` и `end` на расстоянии
 *   друг от друга, заданном `timeInterval`
 */
const splitDuration = (
  start: moment.Moment,
  end: moment.Moment,
  timeInterval: moment.Duration
): SelectData[] => {
  start = roundMomentMinutes(start, timeInterval.minutes(), 'up');
  end = roundMomentMinutes(end, timeInterval.minutes(), 'down');

  start.seconds(0);
  end.seconds(0);

  const timePoints: SelectData[] = [];

  for (
    let currentMoment = start;
    currentMoment <= end;
    currentMoment.add(timeInterval)
  ) {
    timePoints.push({
      label: currentMoment.format('HH:mm'),
      value: currentMoment.format('HH:mm'),
    });
  }

  return timePoints;
};

const useTimeList = (dayValue: string, updateTimeList: boolean) => {
  const selectedCity = useAppSelector(selectLocationCity);
  const isDelivery = useAppSelector(selectIsDelivery);
  const serverTime = useAppSelector(selectServerTime);
  const checkDelivery = useAppSelector(selectCheckDelivery);
  const [availableTimeList, setAvailableTimeList] = useState<SelectData[]>([]);
  const timeZone = useAppSelector<string>(selectTimeZone);
  const currentDayOfWeek = moment.tz(serverTime, timeZone).day();
  const days = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ] as const;

  const day = useMemo(() => {
    return dayValue ? moment(dayValue).day() : currentDayOfWeek;
  }, [dayValue, selectedCity]);

  const workDay = useMemo(() => {
    const workDaySchedule = selectedCity?.work_hours.find(
      (item) => item.week_day === days[day]
    );
    return workDaySchedule;
  }, [selectedCity, days, day, dayValue]);

  const deliveryStart = useMemo(() => {
    if (workDay) {
      return workDay.delivery_start;
    }

    if (isDelivery && checkDelivery.data !== null) {
      return checkDelivery.data.delivery_start;
    }

    return selectedCity?.delivery_start || '';
  }, [workDay, dayValue, isDelivery, checkDelivery]);

  const deliveryEnd = useMemo(() => {
    if (workDay) {
      return workDay.delivery_end;
    }

    if (isDelivery && checkDelivery.data !== null) {
      return checkDelivery.data.delivery_end;
    }

    return selectedCity?.delivery_end || '';
  }, [workDay, dayValue, isDelivery, checkDelivery]);

  const isTheSameDay = useMemo(() => {
    const currentDate = moment.tz(serverTime, timeZone).format('DD/MM/YYYY');
    const selectedDate = moment.tz(dayValue, timeZone).format('DD/MM/YYYY');

    return currentDate === selectedDate;
  }, [dayValue, timeZone]);

  const timeSplit = (time: string) => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return {
      hours,
      minutes,
      seconds,
    };
  };

  const minDate = useMemo(() => {
    const date = new Date(serverTime);
    const offsetTimeStartValue = selectedCity?.default_delivery_time_start;
    const millisecondsToAdd = offsetTimeStartValue * 60 * 1000;
    const newTime = date.getTime() + millisecondsToAdd;
    date.setTime(newTime);

    return date;
  }, [serverTime]);

  /**
   * Определяет временной интервал, в котором пользователь
   * может выбрать время доставки или самовывоза заказа.
   */
  const configureTimeInterval = (): {
    start: moment.Moment;
    end: moment.Moment;
  } => {
    const offsetTimeStartValue = selectedCity?.default_delivery_time_start;

    const start = moment(serverTime);
    const end = moment(serverTime).set(timeSplit(deliveryEnd));

    if (!isTheSameDay) {
      start.set(timeSplit(deliveryStart));
    }

    start.add(moment.duration(offsetTimeStartValue, 'minutes'));

    return { start, end };
  };

  useEffect(() => {
    const { start, end } = configureTimeInterval();

    setAvailableTimeList(
      splitDuration(start, end, moment.duration(15, 'minutes'))
    );
  }, [
    currentDayOfWeek,
    deliveryStart,
    deliveryEnd,
    serverTime,
    day,
    isTheSameDay,
    updateTimeList,
  ]);

  return { availableTimeList, minDate };
};

export default useTimeList;
